<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/04-3快速交付平台.png"/>
        <div class="mobile-head-explain" style="margin-top:-30rem">
            <div class="mobile-head-explain-title">快速交付平台</div>
            <div class="mobile-head-explain-content">旨在帮助大型传统企业克服数字化转型过程中面临的各种挑战，<br/>可以简单，快速，高效地开发出企业级的应用系统。</div>
        </div>
        <div class="mobile-head-catalogue" >
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>总览</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>特点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>优势</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-content1" style="margin-top:3rem;margin-bottom: 3rem;">通过快速交付平台，企业业务部门可直接通过配置实现包含表单、流程、报表等业务功能。此外，我们提供了包括组织架构、
                    权限体系、认证授权等系统功能和流媒体、IM、在线文档等高级功能，使得企业可以免去企业应用基础功能的开发，而专注实现业务功能。<br/>
                    针对业务功能的开发，我们产品还提供了需求、开发、测试规范，并提供基于开发规范的IDE检查插件及代码扫描插件。<br/>
                    除了对企业信息系统功能性需求的支持，我们产品还为企业提供了基于云计算的监控预警、日志分析、高可用、系统容灾、本地系统上云等企业
                    级解决方案以保证系统合规、可靠、高效。
            </div>
            <img class="ware-img" src="../../assets/image/technicalService/04-3-5总览.png"/>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div>
                <el-carousel indicator-position="outside" arrow="never" trigger="click" height="36rem" >
                    <el-carousel-item v-for="item in boxService" :key="item.index">
                        <div class="custom-card">
                            <div class="card-img"><img :src="item.urlImg"></div>
                            <div class="title3-1">{{item.title}}</div>
                            <div class="title3-2">{{item.content}}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div style="margin-bottom:5rem"></div>
            </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="box2-2" style="margin-top:2.4rem"> 
                <div class="card" style="background-color:#F3FAF6;">
                    <img src="../../assets/image/technicalService/04-3-5统一业务.svg" style="width:7rem;height:7rem"/>
                    <div class="content">
                        <div class="title1">统一业务认知</div>
                        <div class="title2">基于可视化应用程序开发环境 ,  借助表单工具、流程设计和报表设计等模块 , 业务人员和技术人员能快速达成业务统   一理解 , 
                            在降低沟通成本的同时能快速完成从业务构想到系统落地的完整闭环。</div>
                    </div>
                    
                </div>
                <div class="card" style="background-color:#F9F9F9; margin-top: 1rem;">
                    <img src="../../assets/image/technicalService/04-3-5多样化.svg"/>
                    <div class="content">
                        <div class="title1">满足多样化需求</div>
                        <div class="title2">平台通过组件搭建的模式构建系统 ,  可以满足企业不同阶段的数字化需求 ,  契合企业当下的业务探索的诉求 ，
                            保障企业的长期竞争力。</div>
                    </div>
                    
                </div>
                <div class="card" style="background-color:#F9F9F9;margin-top: 1rem;">
                    <img src="../../assets/image/technicalService/04-3-5低成本.svg"/>
                    <div class="content">
                        <div class="title1">低成本和高效率</div>
                        <div class="title2">和传统开发模式相比 ,  比孚快速交付平台极大程度的降低了业务系统搭建的人工成本 ,  缩短了开发周期。没有技
                            术背景的用户也能通过平台提供的可视化 的应用搭建系统完成简单业务系统的搭建。</div>
                    </div>
                    
                </div>
                <div class="card" style="background-color:#F3FAF6;margin-top: 1rem;margin-bottom: 5rem;">
                    <img src="../../assets/image/technicalService/04-3-5挖掘数据.svg"/>
                    <div class="content">
                        <div class="title1">挖掘数据资产</div>
                        <div class="title2">比孚快速交付平台信息存储模型具有高度一致性 ,  标准化接口完成企业内多套业务系统数据集成,打破企业内信息孤岛
                            。在统一的数据存储基础上,  管理人员可以快速从企业各类业务数据完成关联关系建立,更好地挖掘企业本身业务潜力。</div>
                    </div>
                    
                </div>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            serviceIndex:1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
            boxService:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-5见即所得.svg"),
                    title:'见即所得',
                    content:'丰富的表单组件 ,  帮助企业用户以简单拖拽即可生成自定义表单页面 ,  轻松实现业务数据定义。',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-1蓝图设计.svg"),
                    title:'流程定制便捷',
                    content:'可视化流程设计，支撑业务流程的高效流转。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1系统演示.svg"),
                    title:'业务事件清晰全面',
                    content:'将机械化重复工作打包成可配置式的业务功能，配合系统提供的APl，简化复杂业务流程下的功能实现。',
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/technicalService/04-3-1功能确认.svg"),
                    title:'权限配置完善',
                    content:'提供基于系统角色、业务角色、多层级组织多租户等管理方式，以及细化到应用、页面、表单、字段等多维度数据权限管控，实现权限的精细化管理。',
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/technicalService/04-3-1功能确认.svg"),
                    title:'二次开发灵活',
                    content:'Amazon云原生基础架构，支持以原生方式进行二次开发和插件式功能的扩展，具备良好的集成特性，并可搭配加速器，延展更为出色的集成能力。',
                },
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 25rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.mobile-content-box2{
    width: 100%;
    display: flex;
    img{
        width: 6.7rem;
        height: 107.7rem;
        margin-top: 3rem;
    }
    .box2-right{
        margin-left: 3.7rem;
        .title1{
            height: 3.1rem;
            font-size: 2.8rem;
            font-family:'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 4.2rem;
        }
        .title2{
            max-width: 56.1rem;
            height: 9rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #4F4F4F;
            line-height: 4rem;
        }
    }
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: auto;
    margin-bottom: 5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.service-img1{
    width: 69rem;
    height: 33.8rem;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    text-align: center;
}
.servie-catalogue{
    display: flex;
    height: 6rem;
    width: 100%;
    display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;
    .title1{
        background: #EDEDED;
        border-radius: 3.1rem;
        display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #333333;
            line-height: 4.2rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            display: flex;
            align-items: center;
        }
    }
    .service-ischecked{
        background: rgba(17,168,79,0.1);
        span{color: #11A84F;}
    }
    
}
.custom-card{
    width: 45.6rem;
    height: 35rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2rem 0px rgba(236,236,236,0.6);
    padding-bottom: 0rem;
    margin-top: 1rem;
    margin: auto;
    .card-img{
        padding-top: 4.8rem;
       img{
        width: 6.4rem;
        height: 6.4rem;
        display: flex;
        margin: auto;
    } 
    }
    
    .title3-1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 4rem;
        text-align: center;

        margin-top: 2rem;
    }
    .title3-2{
        width: 39.6rem;
        height: 7.5rem;
        font-size: 2.2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 3rem;
        text-align: justify;
        margin: auto;

        margin-top: 0.5rem;

    }
}
.custom-card2{
    width: 98%;
    height: 22.5rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2rem 0px rgba(236,236,236,0.6);
    margin: auto;
    display: flex;
    margin-top: 2rem;

    img{
        width: 12rem;
        height: 12rem;
        margin: auto;
        // margin-left: 3rem;
    } 
    .title-box{
        padding-right: 8rem;
        .card-title3-1{
            height: 2rem;
            font-size: 2rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #000000;
            line-height: 3rem;
            text-align: justify;

            margin-top: 6rem;
        }
        .card-title3-2{
            width: 39.6rem;
            height: 7.5rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #4F4F4F;
            line-height: 3rem;
            text-align: justify;
            margin: auto;

            margin-top: 1.5rem;
        }
    }
}
.custom-img1{
    width: 100%;
    height: 16.6rem;

    margin-top: 1rem;
}
.custom-img2{
    width: 67.8rem;
    height: 15.5rem;
    display: flex;
    text-align: center;
    margin-top: 2rem;
}
.read-more{
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 3rem;
    text-align: center;
    margin-bottom:5rem;
    margin-top: 3rem;
}
.box2-2{
    width: 66.5rem;
    margin-bottom: 1rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    .card{
        width: 69.2rem;
        height: 35rem;
        display: flex;
        img{
            // margin: auto;
            width: 6rem;
            height: 6rem;
            margin: 4.5rem 0 0 2rem;
        }
        .content{
            margin: 2rem 0 0 3.3rem;
            .title1{
                font-size: 2.8rem;
                font-family: 'CN_Medium';
                font-weight: 500;
                color: #000000;
                line-height: 5rem;
                margin: 1rem 0 0 0;
            }
            .title2{
                max-width: 50rem;
                font-size: 2.5rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #4F4F4F;
                line-height: 4rem;
                margin: 0rem 0 0 0;
            }
        }
    }

}
</style>